import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const TwymanGhoshal = () => {

	const data = useStaticQuery(graphql`
		query {
			logo: file(relativePath: { eq: "portfolio/identity/atg-logo.png" }) {
				childImageSharp {
					fixed(width: 130) {
						...GatsbyImageSharpFixed_noBase64
					}
				}
			},
			img1: file(relativePath: { eq: "portfolio/atg/01.png" }) {
				childImageSharp {
					fluid(maxWidth: 700) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			}
			img2: file(relativePath: { eq: "portfolio/atg/02.png" }) {
				childImageSharp {
					fluid(maxWidth: 700, quality: 100) {
						...GatsbyImageSharpFluid_noBase64
					}
				}
			}
		}
	`)

	return(
		<Layout>
			<SEO title="Case Studies: TwymanGhoshal" />

			<div className="case-study">
				<div className="intro">
					<div className="brand">
						<Img className='mx-auto' width="130" fixed={data.logo.childImageSharp.fixed} />
					</div>
					<h1 className='page-title display'>_twymanghoshal.com</h1>
					<div className="base-img">
						<Img fluid={data.img1.childImageSharp.fluid} />
					</div>
				</div>

				<div className="row text-center">
					<div className="col-md-7 mx-auto">
						<p className='text-primary-dark mb-4'>Global criminologist, Dr. Twyman-Ghoshal approached Axe Edge to produce a marketing platform for her research projects, public speaking appearances and teaching.</p>
						<div className="mb-4">
							<a className='btn mr-2' href="https://twymanghoshal.com">twymanghoshal.com</a>
						</div>
						<ul>
							<li className='label label-primary mr-1'>Web Design</li>
							<li className='label label-primary mr-1'>Branding</li>
						</ul>
					</div>
				</div>

            	<div className="content-img">
					<Img fluid={data.img2.childImageSharp.fluid} />
				</div>
			</div>
		</Layout>
	)
}

export default TwymanGhoshal
